<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Пригласить пользователя
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
          <b-form-group label="Email">
            <b-form-input
              v-model="form.email"
              type="text"
              autocomplete="false"
              autofocus
              :state="chkState('email')"
              aria-describedby="sharedEmail"
            />
            <b-form-invalid-feedback id="sharedEmail">
              <span v-if="!$v.form.email.required">Поле обязательно для заполнения</span>
              <span v-if="!$v.form.email.email">Поле принимает только email адрес</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            class="form-group-el-select"
            label="Роль"
            label-for="roles"
          >
            <el-select-clearable
              v-model="form.roles"
              class="form-control"
              name="roles"
              placeholder="Выберите одну или несколько ролей"
              clearable
              :multiple="true"
              :filterable="true"
              :remote="true"
              reserve-keyword
              :loading="loading"
            >
              <el-option
                v-for="item in roles"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select-clearable>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :class="{'loading' : loading}"
          :disabled="loading"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
    <contractor-users-roles-info-table />
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import notifications from '../../components/mixins/notifications';
import {contractorsUserCreate} from '../../services/api';
import vSelect from 'vue-select';
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import {validation} from '../../components/mixins/validation';
import ContractorUsersRolesInfoTable from '@/views/ContractorUsers/ContractorUsersRolesInfoTable';

export default {
  name: 'ContractorUserInvite',
  components: {ContractorUsersRolesInfoTable, vSelect, ElSelectClearable},
  mixins: [validationMixin, validation, notifications],
  props: ['contactId'],
  data() {
    return {
      loading: false,
      form: {
        username: '',
        email: '',
        roles: [],
      },
      roles: [
        {value: 'ROLE_ADMIN', text: 'Администратор'},
        {value: 'ROLE_MANAGER', text: 'Менеджер'},
        {value: 'ROLE_VIEW', text: 'Просмотр'},
      ],
      contactRequisite: false,
    };
  },
  validations: {
    form: {
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    async onSubmit() {
      const sendData = {...this.form};
      sendData.username = this.form.email;
      if (this.form.roles.length) {
        sendData.roles = this.form.roles;
        if (this.validate()) {
          this.loading = true;
          const response = await contractorsUserCreate(this.$store.state.user.ownerId, sendData);
          if (response && response.status === 201) {
            this.showSuccess('Пользователю ' + this.form.email + ' отправлено приглашение.');
          }
          this.loading = false;
        }
      } else {
        delete sendData.roles;
        this.showWarn('Выберите как минимум одну роль');
      }
    },
  },
};
</script>

